import React from 'react';
import { css } from '@emotion/core'

function Videos(props) {
    return (
        <div>
            {props.videoData.nodes.map(({ snippet, alternative_id }) => {
                 console.log(snippet);
                 if (snippet && alternative_id) {
                    const thumb = snippet.thumbnails.medium;
                    const videoUrl = "https://www.youtube.com/watch?v=" + alternative_id.videoId;
                    return (<div key={alternative_id.videoId} css={css`
                           clear: both;
                           margin-top: -20px;
                       `}>
                               <h4>{snippet.title}</h4>
                               <p><a href={videoUrl}><img alt="video thumbnail" src={thumb.url} height="90" css={css`
                           padding: 0 15px 0 0;
                           float: left;
                       `} /></a> <span>{snippet.description} (<a href={videoUrl}>watch</a>)
                       </span></p>
                           </div>)
                 } else {
                     return (null)
                 }
            })}
        </div>)
}

export default Videos