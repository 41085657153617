import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography"
import YouTubeSubscribe from "./youtubeSubscribe";

import logo from "./logo.png";

const channelid = "UCrCJmpy47G_bcEYYCZ60NLw";

export default ({ children }) => (
  <div
    css={css`
      margin: 0 auto;
      max-width: 850px;
      padding: ${rhythm(2)};
      padding-top: ${rhythm(1.5)};
    `}
  >
    <Link to={`/`}>
      <div
        css={css`
          display: inline-block;
          font-style: normal;
          color: inherit;
          text-transform: uppercase;
        `}
      >
        <img src={logo} alt="type with me logo" height="75" />
      </div>
    </Link>
    <Link
      to={`/about/`}
      css={css`
        float: right;
      `}
    >
      About
    </Link>
    {children}
    <footer css={css`
        margin-top: 20px;
      `}>
      <hr />
      <div css={css`
        color: #aaa;
        font-size: 13px
      `}>subscribe to the typewithme channel</div>
      <YouTubeSubscribe
            // channelName={channelName}
            channelid={channelid}
            theme={"default"}
            layout={"default"}
            count={"default"}
          />
    </footer>
  </div>
)